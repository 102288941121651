(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/render-context/assets/javascripts/render-context.js') >= 0) return;  svs.modules.push('/components/tipsen/render-context/assets/javascripts/render-context.js');
"use strict";


const {
  createContext
} = React;
const RenderContext = createContext();
setGlobal('svs.component.tipsen.renderContext.RenderContext', RenderContext);

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/render-context/assets/javascripts/render-provider.js') >= 0) return;  svs.modules.push('/components/tipsen/render-context/assets/javascripts/render-provider.js');
"use strict";


const {
  useMemo
} = React;
const {
  RenderContext
} = svs.component.tipsen.renderContext;
const {
  pageTypes
} = svs.component.tipsen.renderContext.constants;
const RenderProvider = _ref => {
  let {
    children,
    pageType
  } = _ref;
  const context = useMemo(() => ({
    pageType,
    isResultOrMybets: pageType === pageTypes.RESULT || pageType === pageTypes.MY_BETS,
    isResult: pageType === pageTypes.RESULT,
    isMyBet: pageType === pageTypes.MY_BETS
  }), [pageType]);
  return React.createElement(RenderContext.Provider, {
    value: context
  }, children);
};
setGlobal('svs.component.tipsen.renderContext.RenderProvider', RenderProvider);

 })(window);
(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/tipsen/render-context/assets/javascripts/constants.js') >= 0) return;  svs.modules.push('/components/tipsen/render-context/assets/javascripts/constants.js');
"use strict";


const pageTypes = {
  DEFAULT: 'default',
  RESULT: 'result',
  MY_BETS: 'myBets'
};
setGlobal('svs.component.tipsen.renderContext.constants', {
  pageTypes
});

 })(window);